import React from 'react';

import './DividerLine.scss'

function DividerLine() {
  return (
    <div className='app__dividerline'></div>
  )
}

export default DividerLine